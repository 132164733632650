/* eslint-disable jsx-a11y/anchor-is-valid */
import { useSelector } from "react-redux"
import FilterBloomTime from "./FilterBloomTime"
import FilterColor from "./FilterColor"
import FilterGenus from "./FilterGenus"
import FilterBulbAttributes from "./FilterBulbAttributes"
import FilterAvailability from "./FilterAvailability"
import FilterHeight from "./FilterHeight"

const FilterContainer = ({ displayBloomTime, displayColor, displayBulbAttribute, displayFilter}) => {
  const filter = useSelector(({ filter }) => filter)
  const { REACT_APP_ENABLE_AVAILABILITY } = process.env

  if(!displayFilter) {
    return (
      <div className='collapsedFilter is-hidden-touch'>
      </div>
    )
  }
  else {
    return (
      <aside className='menu is-size-5-tablet is-hidden-touch' id='filterContainer' display={{'width': '100%'}}>

        <div>
          {REACT_APP_ENABLE_AVAILABILITY === 'true' && 
            <FilterAvailability availableOnly={filter.availableOnly} />
          }
          <FilterGenus genus={filter.genus} bulbCategories={filter.bulbCategories} />
          <FilterColor colors={filter.color} displayColor={displayColor} />
          <FilterHeight />
          <FilterBloomTime bloomTime={filter.bloomTime} displayBloomTime={displayBloomTime} />
          <FilterBulbAttributes bulbAttributes={filter.bulbAttributes} displayBulbAttributes={displayBulbAttribute} />
        </div>
      </aside>
    )
  }
}

export default FilterContainer