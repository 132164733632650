const sortBulbs = (bulbs, filter) => {
  // Variables for determing what genus and color are available
  const displayBloomTime = {};
  const displayBulbAttribute = {};
  const displayColor = {};
  const { REACT_APP_ENABLE_AVAILABILITY } = process.env;
  let sortedBulbs = { ...bulbs };

  // Filters bulbs by the selected genus filter
  for (const genus in filter.genus) {
    if (filter.genus[genus]) {
      sortedBulbs = {
        [genus]: sortedBulbs[genus],
      };
      // Filter genus by selected genus category
      for (const category in filter.bulbCategories[genus]) {
        if (filter.bulbCategories[genus][category]) {
          sortedBulbs[genus] = sortedBulbs[genus]?.filter((bulb) =>
            bulb.bulbCategories.includes(category)
          );
          break;
        }
      }
    }
  }

  // Populates display objects to filter out categories that return no bulbs
  for (const genus in sortedBulbs) {
    sortedBulbs[genus]?.forEach((bulb) => {
      bulb.bloomtime.forEach((time) => {
        if (displayBloomTime[time] === undefined) {
          displayBloomTime[time] = true;
        }
      });
      bulb.colors.forEach((color) => {
        if (displayColor[color] === undefined) {
          displayColor[color] = true;
        }
      });
      bulb.bulbAttributes.forEach((attribute) => {
        if (displayBulbAttribute[attribute] === undefined) {
          displayBulbAttribute[attribute] = true;
        }
      });
    });
  }

  // Filters bulbs by the selected color filter
  for (const color in filter.color) {
    if (filter.color[color]) {
      for (const genus in sortedBulbs) {
        sortedBulbs[genus] = sortedBulbs[genus]?.filter((bulb) =>
          bulb.colors.includes(color)
        );
      }
    }
  }

  // Filters bulbs by the selected bloom time filter
  for (const time in filter.bloomTime) {
    if (filter.bloomTime[time]) {
      for (const genus in sortedBulbs) {
        sortedBulbs[genus] = sortedBulbs[genus]?.filter((bulb) =>
          bulb.bloomtime.includes(time)
        );
      }
    }
  }

  // Filters bulbs by the selected bulb attribute filter
  for (const attribute in filter.bulbAttributes) {
    if (filter.bulbAttributes[attribute]) {
      for (const genus in sortedBulbs) {
        sortedBulbs[genus] = sortedBulbs[genus]?.filter((bulb) =>
          bulb.bulbAttributes.includes(attribute)
        );
      }
    }
  }

  for (const genus in sortedBulbs) {
    // Filter bulbs by alphabetical
    sortedBulbs[genus] = [...sortedBulbs[genus]].sort((a, b) => {
      a = a.sortName.toLowerCase();
      b = b.sortName.toLowerCase();
      return a < b ? -1 : a > b ? 1 : 0;
    });

    // Filter available bulbs
    if (REACT_APP_ENABLE_AVAILABILITY === "true") {
      if (filter.availableOnly) {
        sortedBulbs[genus] = sortedBulbs[genus]?.filter(
          (bulb) => bulb.available
        );
      } else {
        sortedBulbs[genus] = [...sortedBulbs[genus]]?.sort(
          (a, b) => Number(b.available) - Number(a.available)
        );
      }
    }

    // If search string input is enabled
    if (filter.searchString !== "") {
      sortedBulbs[genus] = sortedBulbs[genus]?.filter((bulb) => {
        const cleanBulbName = bulb.name.toLowerCase();
        return cleanBulbName.includes(filter.searchString.toLowerCase());
      });
    }

    // Filters bulbs by the selected height filter
    sortedBulbs[genus] = sortedBulbs[genus]?.filter(
      (bulb) =>
        bulb.minHeight >= filter.minHeight && bulb.maxHeight <= filter.maxHeight
    );
  }

  return { sortedBulbs, displayBloomTime, displayBulbAttribute, displayColor };
};

export default sortBulbs;
