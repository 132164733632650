// scrolls to the top of the CatalogDisplay and the body element
const topFunction = () => {
  const container = document.getElementById("catalogDisplay");
  // document.body.scrollTop = 0;
  // document.documentElement.scrollTop = 0;
  // container.scrollTop = 0;

  container.scroll({
    top: 0,
    behavior: "smooth",
  });
};

export default topFunction;
