import { useDispatch } from "react-redux";
import { setDisplay, setModal } from "../../reducers/modalReducer";

const CatalogCard = ({
  available,
  name,
  featured_img,
  height,
  season,
  sun,
}) => {
  const { REACT_APP_ENABLE_AVAILABILITY } = process.env;
  const dispatch = useDispatch();

  // activate modal
  const handleModal = () => {
    const bulbObj = {
      name: name,
      featured_img: featured_img,
    };

    dispatch(setModal(bulbObj));
    dispatch(setDisplay(true));
  };

  return (
    <div className="card">
      {/* loads images dynamically */}
      <div className="card-image">
        <figure
          className="image is-align-items-center is-flex"
          onClick={handleModal}
          id="catalogImage"
        >
          {/* Display sold out */}
          {!available && REACT_APP_ENABLE_AVAILABILITY === "true" && (
            <div className="unavailable is-size-3 is-justify-content-center is-align-items-center is-flex py-4 has-text-weight-bold">
              UNAVAILABLE
            </div>
          )}
          <img src={featured_img} alt="bulb" loading="lazy"></img>
        </figure>
      </div>

      <div className="card-content">
        <div className="content">
          <p className="title is-size-4-tablet is-size-6-mobile">{name}</p>
          <div className="is-size-7-mobile is-size-6-tablet is-size-5-desktop">
            Bloom Time: {season}
            <br />
            Height: {height} inches
            <br />
            Sun: {sun}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CatalogCard;
