import { createSlice } from '@reduxjs/toolkit'
import bulbService from '../services/Bulbs'

const bulbSlice = createSlice({
  name: 'bulbs',
  initialState: {},
  reducers: {
    setBulbs(state, action){
      return action.payload
    },
  }
})

export const { setBulbs } = bulbSlice.actions

export const initializeBulbs = () => {
  return async dispatch => {
    const bulbs = await bulbService.getAll()
    dispatch(setBulbs(bulbs))
  }
}

export default bulbSlice.reducer