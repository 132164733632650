/* eslint-disable jsx-a11y/anchor-is-valid */
import Nouislider from "nouislider-react";
import { useDispatch, useSelector } from "react-redux";
import { setHeight } from "../../reducers/filterReducer";
import topFunction from "../../utils/scrollTop";

const FilterHeight = ({ isMobile }) => {
  if (isMobile) {
    return (
      <div className="field">
        <div className="control is-size-3-tablet is-size-5-mobile">
          <div>
            <ul className="menu-list">
              <NoUISliderComponent />
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p className="menu-label has-text-weight-bold my-4">height</p>
        <ul className="menu-list">
          <NoUISliderComponent />
        </ul>
      </div>
    );
  }
};

const NoUISliderComponent = () => {
  const [minHeight, maxHeight] = useSelector(({ filter }) => [
    filter.minHeight,
    filter.maxHeight,
  ]);
  const dispatch = useDispatch();

  const displayMax = (event) => {
    dispatch(
      setHeight({
        minHeight: Math.trunc(event[0]),
        maxHeight: Math.trunc(event[1]),
      })
    );

    topFunction();
  };

  return (
    <div className="pr-3">
      <p className="pb-3 has-text-centered">
        {minHeight} - {maxHeight} inches
      </p>
      <Nouislider
        range={{ min: 0, max: 50 }}
        start={[minHeight, maxHeight]}
        step={5}
        connect
        onUpdate={displayMax}
      />
    </div>
  );
};

export default FilterHeight;
