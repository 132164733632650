import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { initializeBulbs } from "./reducers/bulbReducer.js";
import NavBar from "./components/NavBar";
import CatalogContainer from "./components/CatalogContainer";
import { Route, Routes } from "react-router-dom";
import useWindowDimensions from "./utils/windowDimensions.js";
import { setTouchMode, toggleDisplay } from "./reducers/filterReducer.js";
import InspirationContainer from "./components/InspirationContainer.js";

const App = () => {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  useEffect(() => {
    dispatch(initializeBulbs());

    // desktop width
    if (width < 1024) {
      dispatch(setTouchMode(true));
      dispatch(toggleDisplay(false)); // We don't want the filter to be displayed unlessed toggled
    } else {
      dispatch(setTouchMode(false));
    }
  }, [dispatch, width]);

  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/Inspiration" element={<InspirationContainer />} />
        <Route path="/Catalog" element={<CatalogContainer />} />

        {/* default for all unknown addresses */}
        <Route path="/*" element={<CatalogContainer />} />
      </Routes>
    </div>
  );
};

export default App;
