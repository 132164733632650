import { useDispatch, useSelector } from "react-redux";
import { setDisplay } from "../../reducers/modalReducer";

const CatalogCardModal = () => {
  const [bulb, display] = useSelector(({ modal }) => [
    modal.bulb,
    modal.display,
  ]);
  const dispatch = useDispatch();

  // deactivate modal
  const handleModal = () => {
    dispatch(setDisplay(false));
  };

  const displayStyle = () => {
    let classStyle = ["modal"];

    if (display) {
      classStyle.push(["is-active"]);
    }

    return classStyle.join(" ");
  };

  return (
    <div>
      {display && (
        <div className={displayStyle()}>
          <div
            className="modal-background is-clickable"
            onClick={handleModal}
          ></div>
          <div className="modal-content" id="catalogModalContent">
            <figure
              className="is-flex is-align-items-flex-end"
              style={{ maxHeight: "inherit" }}
            >
              <div className="inspirationTitleModal is-size-4 py-4 px-5 mb-6 is-flex has-text-weight-bold">
                {bulb.name}
              </div>
              <img src={bulb.featured_img} alt="inspiration page" />
            </figure>
          </div>
          <button
            className="modal-close is-large"
            aria-label="close"
            onClick={handleModal}
          ></button>
        </div>
      )}
    </div>
  );
};

export default CatalogCardModal;
