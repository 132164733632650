import React from "react";
import { useDispatch } from "react-redux";
import { filterBulbCategories } from "../../reducers/filterReducer";
import topFunction from "../../utils/scrollTop";
import cleanseName from "../../utils/cleanseName";

const findSelectedGenus = (filter) => {
  for (const key in filter.genus) {
    if (filter.genus[key] === true) {
      return key;
    }
  }
};

const FilterMobileCategories = ({ filter }) => {
  const dispatch = useDispatch();
  const selectedGenus = findSelectedGenus(filter);
  const options = [];

  // populate options array
  for (const key in filter.bulbCategories[selectedGenus]) {
    if (key !== undefined) {
      options.push(
        <option value={key} key={`option-${key}`}>
          {cleanseName(key)}
        </option>
      );
    }
  }

  const filterSpecialtyCategoryInput = (event) => {
    dispatch(
      filterBulbCategories({
        genus: selectedGenus,
        category: event.target.value,
      })
    );

    topFunction();
  };

  return (
    <React.Fragment>
      <h1
        className="title is-size-2-tablet is-size-4-mobile"
        style={{ display: options.length === 0 ? "none" : "block" }}
      >
        Category
      </h1>
      <div
        className="field"
        style={{ display: options.length === 0 ? "none" : "block" }}
      >
        <div className="control">
          <div className="select is-normal is-rounded is-success">
            <select onChange={filterSpecialtyCategoryInput}>{options}</select>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default FilterMobileCategories;
