/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react"
import { filterBulbAttributes} from "../../reducers/filterReducer"
import { useDispatch } from "react-redux"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'
import topFunction from '../../utils/scrollTop'

const FilterBulbAttributes= ({ bulbAttributes, displayBulbAttributes, isMobile }) => {
  const [display, setDisplay] = useState(true)
  const dispatch = useDispatch()

  // toggle display menu items
  const toggleDisplay = () => {
    setDisplay(!display)
  }

  const displayStyle = (attribute) => {
    let classStyle = ['btnBulbAttribute']

    if(isMobile){
      classStyle.push('button', 'is-medium', 'is-rounded', 'm-2')
    }

    // display an active style for the selected bulb attribute filter
    if(bulbAttributes[attribute]){
      classStyle.push('is-active', 'is-success')
    }

    // hides bulb attributes filters if not applicable aka zero bulbs
    if(displayBulbAttributes && displayBulbAttributes[attribute] === undefined){
      classStyle.push(['is-hidden'])
    }

    return classStyle.join(' ')
  }

  const filterBulbAttributesInput = (event) => {
    event.preventDefault()

    switch(event.target.id){
      case 'btnResistantToDeer':
        dispatch(filterBulbAttributes('deer-resistant'))
        break;
      case 'btnShadeTolerant':
        dispatch(filterBulbAttributes('shade-tolerant'))
        break;
      case 'btnGoodForNaturalizing':
        dispatch(filterBulbAttributes('for-naturalizing'))
        break;
      case 'btnForTheSouth':
        dispatch(filterBulbAttributes('for-the-south'))
        break;
      case 'btnGoodForCutting':
        dispatch(filterBulbAttributes('for-cutting'))
        break;
      default:
    }

    topFunction()
  }
  if (isMobile){
    return (
      <div className="field is-grouped is-grouped-multiline">
        <div id='btnResistantToDeer' className={displayStyle('deer-resistant')} onClick={filterBulbAttributesInput}>Resistant To Deer</div>
        <div id='btnShadeTolerant' className={displayStyle('shade-tolerant')} onClick={filterBulbAttributesInput}>Shade Tolerant</div>
        <div id='btnGoodForNaturalizing' className={displayStyle('for-naturalizing')} onClick={filterBulbAttributesInput}>Good For Naturalizing</div>
        <div id='btnForTheSouth' className={displayStyle('for-the-south')} onClick={filterBulbAttributesInput}>For The South</div>
        <div id='btnGoodForCutting' className={displayStyle('for-cutting')} onClick={filterBulbAttributesInput}>Good For Cutting</div>
      </div>
    )
  }
  else {
    return(
      <div>
        <p className='menu-label my-4 has-text-weight-bold is-clickable' onClick={toggleDisplay}>
            Bulb Attributes
            <span className="icon is-small filterCategoryCollapse">
              {display
                ? <FontAwesomeIcon icon={faAngleUp} />
                : <FontAwesomeIcon icon={faAngleDown} />
              }
            </span> 
          </p>
        {display && 
          <ul className="menu-list">
            <li><a href='#' id='btnResistantToDeer' className={displayStyle('deer-resistant')} onClick={filterBulbAttributesInput}>Resistant To Deer</a></li>
            <li><a href='#' id='btnShadeTolerant' className={displayStyle('shade-tolerant')} onClick={filterBulbAttributesInput}>Shade Tolerant</a></li>
            <li><a href='#' id='btnGoodForNaturalizing' className={displayStyle('for-naturalizing')} onClick={filterBulbAttributesInput}>Good For Naturalizing</a></li>
            <li><a href='#' id='btnForTheSouth' className={displayStyle('for-the-south')} onClick={filterBulbAttributesInput}>For The South</a></li>
            <li><a href='#' id='btnGoodForCutting' className={displayStyle('for-cutting')} onClick={filterBulbAttributesInput}>Good For Cutting</a></li>
          </ul>
        }
      </div>
    )
  }
}
export default FilterBulbAttributes