import CatalogCard from "./CatalogCard";
import CatalogLoading from "./CatalogLoading";
import CatalogNoResults from "./CatalogNoResults";

const CatalogCardGrid = ({ bulbs, selectedGenus, displayFilter }) => {
  const rows = [];
  let bulbCount = 0;

  const displayStyle = () => {
    let classStyle = ["column", "is-4-tablet", "is-12-mobile"];

    if (displayFilter) {
      classStyle.push(["is-3-desktop"]);
    } else {
      classStyle.push(["is-one-fifth-desktop"]);
    }

    return classStyle.join(" ");
  };

  for (const genus in bulbs) {
    // genus section headers
    if (selectedGenus === undefined && bulbs[genus].length !== 0) {
      rows.push(
        <div key={`${genus}Header`} className="column is-12">
          <h1 className="title is-size-3-mobile is-size-2-tablet py-1">
            {genus.toUpperCase()}
          </h1>
        </div>
      );
    }

    // Create a card for each bulb
    bulbs[genus]?.forEach((bulb, index) => {
      rows.push(
        <div key={`column${genus}${index}`} className={displayStyle()}>
          <CatalogCard
            key={bulb.bulb_id}
            available={bulb.available}
            name={bulb.name}
            height={bulb.height}
            sun={bulb.sun}
            season={bulb.season}
            featured_img={bulb.featured_img}
          />
        </div>
      );
    });

    // find the current bulb count
    bulbCount += bulbs[genus]?.length;
  }

  if (Object.keys(bulbs).length === 0) {
    return (
      <div>
        <CatalogLoading />
      </div>
    );
  } else if (bulbCount === 0) {
    return <CatalogNoResults />;
  } else {
    return <div className="columns is-mobile is-multiline">{rows}</div>;
  }
};

export default CatalogCardGrid;
