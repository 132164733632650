const CatalogHeader = ({ genus }) => {
  const title = genus !== undefined ? genus.toUpperCase() : 'ALL FLOWER BULBS'

  return (
    <section className='hero is-small'>
      <div className='hero-body has-text-centered has-text-black-bis'>
        <p className='title is-size-4-mobile is-size-1-tablet' id='catalogHeader'>
          {title}
        </p>
      </div>
    </section>
  )
}

export default CatalogHeader