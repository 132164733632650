/* eslint-disable jsx-a11y/anchor-is-valid */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faFilter } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  setSearchString,
  resetAllFilters,
  toggleDisplay,
} from "../reducers/filterReducer";
import { Link, useLocation } from "react-router-dom";
import { setDisplay } from "../reducers/modalReducer";

const NavBar = () => {
  const searchString = useSelector(({ filter }) => filter.searchString);
  const dispatch = useDispatch();
  let location = useLocation().pathname;

  // functionality for search bar
  const handleSearchChange = (event) => {
    const searchQuery = event.currentTarget.value;
    dispatch(setSearchString(searchQuery));
  };

  // reset states inside the catalog page
  const reset = () => {
    dispatch(resetAllFilters());
    dispatch(setDisplay(false));
    toggleBurger(false);
  };

  // displays search bar when button is activated (for mobile purposes)
  const displaySearchBar = () => {
    const searchBar = document.getElementById("searchDropDown");
    const btnSearch = document.getElementById("btnSearch");
    btnSearch.classList.toggle("activeNavLink");
    btnSearch.classList.toggle("is-active");
    searchBar.classList.toggle("is-active");
  };

  const toggleBurger = (doToggle) => {
    const menu = document.getElementById("navBar");

    if (doToggle) {
      menu?.classList.toggle("is-active");
    } else {
      menu?.classList.remove("is-active");
    }
  };

  const toggleFilters = () => {
    dispatch(toggleDisplay());
  };

  return (
    <nav
      className="navbar is-fixed-top is-success"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand is-justify-content-space-between">
        <Link
          className="navbar-item is-size-3-desktop is-size-5-mobile has-text-weight-bold"
          to={"/"}
          onClick={reset}
        >
          <span id="spring">Spring</span> Displays
        </Link>

        <div className="is-flex is-justify-content-flex-end is-align-items-stretch">
          {/* touch search button */}
          {(location === "/Catalog" || location === "/") && (
            <div
              className="navbar-item dropdown is-right is-hidden-desktop"
              id="searchDropDown"
            >
              <div className="dropdown-trigger">
                <button
                  className="button is-ghost is-size-7"
                  id="btnSearch"
                  onClick={displaySearchBar}
                >
                  <span className="icon is-large id-left has-text-white">
                    <FontAwesomeIcon icon={faSearch} />
                  </span>
                </button>

                <div className="dropdown-menu" id="dropdown-menu" role="menu">
                  <div className="dropdown-content" id="searchBar">
                    <div className="dropdown-item">
                      <p className="control has-icons-left">
                        <input
                          className="input"
                          value={searchString}
                          onChange={handleSearchChange}
                          type="text"
                          placeholder="Search for Bulbs"
                        />

                        <span className="icon is-small is-left">
                          <FontAwesomeIcon icon={faSearch} />
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          {/* touch toggable filter button */}
          {(location === "/Catalog" || location === "/") && (
            <div className="navbar-item is-hidden-desktop">
              <button
                className="button is-ghost is-size-7"
                id="btnFilter"
                onClick={toggleFilters}
              >
                <span className="icon is-large id-left has-text-white">
                  <FontAwesomeIcon icon={faFilter} />
                </span>
              </button>
            </div>
          )}

          {/* hamburger only appears in touch view */}
          <a
            role="button"
            className="navbar-burger"
            aria-label="menu"
            aria-expanded="false"
            data-target="navBar"
            onClick={toggleBurger}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
      </div>

      <div id="navBar" className="navbar-menu is-justify-content-center">
        <div className="navbar-start ml-5">
          {/* displays active class if on catalog page */}
          {location === "/Catalog" || location === "/" ? (
            <Link
              className="navbar-item is-size-3-tablet is-size-4-mobile activeNavLink"
              to="/Catalog"
              onClick={toggleBurger(false)}
            >
              Catalog
            </Link>
          ) : (
            <Link
              className="navbar-item is-size-3-tablet is-size-4-mobile"
              to="/Catalog"
              onClick={toggleBurger(false)}
            >
              Catalog
            </Link>
          )}

          {/* displays active class if on inspiration page */}
          {location === "/Inspiration" ? (
            <Link
              className="navbar-item is-size-3-tablet is-size-4-mobile activeNavLink"
              to="/Inspiration"
              onClick={reset}
            >
              Inspiration
            </Link>
          ) : (
            <Link
              className="navbar-item is-size-3-tablet is-size-4-mobile"
              to="/Inspiration"
              onClick={reset}
            >
              Inspiration
            </Link>
          )}

          {(location === "/Catalog" || location === "/") && (
            <div className="navbar-item mt-2 is-hidden-touch" id="searchBar">
              <p className="control has-icons-left">
                <input
                  className="input is-rounded"
                  value={searchString}
                  onChange={handleSearchChange}
                  type="text"
                  placeholder="Search for Bulbs"
                />

                <span className="icon is-small is-left">
                  <FontAwesomeIcon icon={faSearch} />
                </span>
              </p>
            </div>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
