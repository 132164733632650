import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: {
    bulb: {},
    display: false,
  },
  reducers: {
    setModal(state, action) {
      state.bulb = action.payload;
    },
    setDisplay(state, action) {
      state.display = action.payload;
    },
  },
});

export const { setModal, setDisplay } = modalSlice.actions;

export default modalSlice.reducer;
