import { useDispatch } from "react-redux"
import { setTouchMode, toggleDisplay } from "../../reducers/filterReducer"

const CollapseBtn = ({ display }) => {
  const dispatch = useDispatch()

  const displayFilters = () => {
    dispatch(toggleDisplay(!display))
    dispatch(setTouchMode(false))
  }

  return (
    <button className="button btnCollapse" onClick={displayFilters}>
      {display ? '>' : '<'}
    </button>
  )
}

export default CollapseBtn