/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { filterColor } from "../../reducers/filterReducer";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import topFunction from "../../utils/scrollTop";

const FilterColor = ({ colors, displayColor, isMobile }) => {
  const [display, setDisplay] = useState(true);
  const dispatch = useDispatch();

  // toggle display menu items
  const toggleDisplay = () => {
    setDisplay(!display);
  };

  const displayColorItem = (color) => {
    let classStyle = [];

    if (!isMobile) {
      classStyle.push("column", "is-4-tablet", "is-3-widescreen");
    }

    if (displayColor && displayColor[color] === undefined) {
      classStyle.push(["is-hidden"]);
    }

    return classStyle.join(" ");
  };

  const displayStyle = (color) => {
    let classStyle = ["button", "btnColor"];

    if (isMobile) {
      classStyle.push("is-large", "is-rounded", "m-2");
    } else {
      classStyle.push("is-4-tablet", " is-3-widescreen");
    }

    // display an active style for the selected color filter
    if (colors[color]) {
      classStyle.push(["is-active"]);
    }

    return classStyle.join(" ");
  };

  const filterColorInput = (event) => {
    dispatch(filterColor(event.target.id));
    topFunction();
  };

  if (isMobile) {
    return (
      <div className="field is-grouped is-grouped-multiline">
        <div className={displayColorItem("red")}>
          <div
            className={displayStyle("red")}
            id="red"
            onClick={filterColorInput}
            style={{ backgroundColor: "#EE385E" }}
          >
            {""}
          </div>
          <div
            style={{
              fontSize: ".9rem",
              textAlign: "center",
            }}
          >
            Red
          </div>
        </div>
        <div className={displayColorItem("orange")}>
          <div
            className={displayStyle("orange")}
            id="orange"
            onClick={filterColorInput}
            style={{ backgroundColor: "#F05B25" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Orange</div>
        </div>
        <div className={displayColorItem("apricot")}>
          <div
            className={displayStyle("apricot")}
            id="apricot"
            onClick={filterColorInput}
            style={{ backgroundColor: "#FFCC66" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Apricot</div>
        </div>
        <div className={displayColorItem("yellow")}>
          <div
            className={displayStyle("yellow")}
            id="yellow"
            onClick={filterColorInput}
            style={{ backgroundColor: "#FCF55F" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Yellow</div>
        </div>
        <div className={displayColorItem("cream")}>
          <div
            className={displayStyle("cream")}
            id="cream"
            onClick={filterColorInput}
            style={{ backgroundColor: "#FFE5B4" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Cream</div>
        </div>
        <div className={displayColorItem("white")}>
          <div
            className={displayStyle("white")}
            id="white"
            onClick={filterColorInput}
            style={{ backgroundColor: "#f4f3f0" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>White</div>
        </div>
        <div className={displayColorItem("green")}>
          <div
            className={displayStyle("green")}
            id="green"
            onClick={filterColorInput}
            style={{ backgroundColor: "#00AC5B" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Green</div>
        </div>
        <div className={displayColorItem("blue")}>
          <div
            className={displayStyle("blue")}
            id="blue"
            onClick={filterColorInput}
            style={{ backgroundColor: "#286AC8" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Blue</div>
        </div>
        <div className={displayColorItem("maroon")}>
          <div
            className={displayStyle("maroon")}
            id="maroon"
            onClick={filterColorInput}
            style={{ backgroundColor: "#4E1431" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Maroon</div>
        </div>
        <div className={displayColorItem("purple")}>
          <div
            className={displayStyle("purple")}
            id="purple"
            onClick={filterColorInput}
            style={{ backgroundColor: "#914296" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Purple</div>
        </div>
        <div className={displayColorItem("lavender")}>
          <div
            className={displayStyle("lavender")}
            id="lavender"
            onClick={filterColorInput}
            style={{ backgroundColor: "#9966FF" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Lavender</div>
        </div>
        <div className={displayColorItem("pink")}>
          <div
            className={displayStyle("pink")}
            id="pink"
            onClick={filterColorInput}
            style={{ backgroundColor: "#E93E96" }}
          >
            {""}
          </div>
          <div style={{ fontSize: ".9rem", textAlign: "center" }}>Pink</div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p
          className="menu-label my-4 is-clickable has-text-weight-bold"
          onClick={toggleDisplay}
        >
          Color
          <span className="icon is-small filterCategoryCollapse">
            {display ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </p>
        {display && (
          <ul className="menu-list">
            <div className="columns is-mobile is-multiline is-1 is-variable">
              <div className={displayColorItem("red")}>
                <div
                  className={displayStyle("red")}
                  id="red"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#EE385E", width: "100%" }}
                >
                  {""}
                </div>
                <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                  Red
                </div>
              </div>
              <div className={displayColorItem("orange")}>
                <div
                  className={displayStyle("orange")}
                  id="orange"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#F05B25", width: "100%" }}
                >
                  {""}
                </div>
                <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                  Orange
                </div>
              </div>
              <div className={displayColorItem("apricot")}>
                <div
                  className={displayStyle("apricot")}
                  id="apricot"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#FFCC66", width: "100%" }}
                >
                  {""}
                </div>
                <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                  Apricot
                </div>
              </div>
              <div className={displayColorItem("yellow")}>
                <div
                  className={displayStyle("yellow")}
                  id="yellow"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#FCF55F", width: "100%" }}
                >
                  {""}
                </div>
                <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                  Yellow
                </div>
              </div>
              <div className={displayColorItem("cream")}>
                <div
                  className={displayStyle("cream")}
                  id="cream"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#FFE5B4", width: "100%" }}
                >
                  {""}
                </div>
                <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                  Cream
                </div>
              </div>
              <div className={displayColorItem("white")}>
                <div
                  className={displayStyle("white")}
                  id="white"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#f4f3f0", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    White
                  </div>
                </div>
              </div>
              <div className={displayColorItem("green")}>
                <div
                  className={displayStyle("green")}
                  id="green"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#00AC5B", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Green
                  </div>
                </div>
              </div>
              <div className={displayColorItem("blue")}>
                <div
                  className={displayStyle("blue")}
                  id="blue"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#286AC8", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Blue
                  </div>
                </div>
              </div>
              <div className={displayColorItem("maroon")}>
                <div
                  className={displayStyle("maroon")}
                  id="maroon"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#4E1431", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Maroon
                  </div>
                </div>
              </div>
              <div className={displayColorItem("purple")}>
                <div
                  className={displayStyle("purple")}
                  id="purple"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#914296", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Purple
                  </div>
                </div>
              </div>
              <div className={displayColorItem("lavender")}>
                <div
                  className={displayStyle("lavender")}
                  id="lavender"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#9966FF", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Lavender
                  </div>
                </div>
              </div>
              <div className={displayColorItem("pink")}>
                <div
                  className={displayStyle("pink")}
                  id="pink"
                  onClick={filterColorInput}
                  style={{ backgroundColor: "#E93E96", width: "100%" }}
                >
                  {""}
                </div>
                <div>
                  <div style={{ fontSize: ".8rem", textAlign: "center" }}>
                    Pink
                  </div>
                </div>
              </div>
            </div>
          </ul>
        )}
      </div>
    );
  }
};

export default FilterColor;
