import { configureStore } from "@reduxjs/toolkit";

import bulbReducer from "./reducers/bulbReducer.js";
import filterReducer from "./reducers/filterReducer.js";
import modalReducer from "./reducers/modalReducer.js";

const store = configureStore({
  reducer: {
    bulbs: bulbReducer,
    filter: filterReducer,
    modal: modalReducer,
  },
});

export default store;
