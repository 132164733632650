import { useState } from 'react'
import { useSelector } from 'react-redux'
import topFunction from '../../utils/scrollTop'

const ScrollTopBtn = () => {
  const [display, setDisplay] = useState(false)
  const isModalActive = useSelector(({ modal }) => modal.display)
  const container = document.getElementById('catalogDisplay')

  // checks if the container has been rendered
  if(container !== null){
    container.onscroll = function () {scrollFunction()}
  }
  
  // checks if the container has scrolled past the threshold
  const scrollFunction = () => {
    if (container.scrollTop > 1000 || document.documentElement.scrollTop > 1000){
      setDisplay(true)
    }
    else {
      setDisplay(false)
    }
  }

  if(display && !isModalActive){
    return (
      <button id='scrollTopBtn' onClick={topFunction}>
        Top
      </button>
    )
  }
  else{
    return (
      <div className='is-hidden'></div>
    )
  }
}

export default ScrollTopBtn