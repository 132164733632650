import React, { useEffect, useRef, useState } from "react";
import useWindowDimensions from "../utils/windowDimensions.js";

// All images needs to be roughly 1024 x 680
const inspirations = [
  {
    id: 0,
    title:
      "A river of daffodils Mount Hood and Delnashaugh winds through an expanse of rough turf",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/3051_Delnashaugh_CWH4671P31.jpg",
  },
  {
    id: 1,
    title: "Hot Hot Hot tulip blend",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2020/07/1210_HotHotHot_CGC4614-1024x680.jpg",
  },
  {
    id: 2,
    title: "Gentle Giants tulip blend with Grape Hyacinths",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2020/07/CWH_L1202678-1024x678.jpg",
  },
  {
    id: 3,
    title: "Tulip blend Skyliners and daffodil Thalia",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2021/06/SkylinersThalia_AGB4651-1024x680.png",
  },
  {
    id: 4,
    title: "Tulip Flair with daffodil Jetfire",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2020/03/FlairJetfire_CGC0496-1024x680.jpg",
  },
  {
    id: 5,
    title: "Striped Squill with Blue Squill in turf.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/PuschkiniaBlueSquill_CGC7828.jpg",
  },
  {
    id: 6,
    title: "Allium Globemaster with catmint (Nepeta).",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/GlobemasterCatmint_CWH_L1040423-1.jpg",
  },
  {
    id: 7,
    title: "Tulip Daydream with gold-leaved bleeding heart.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/DaydreamBleedingHeart_CWH4245-1.jpg",
  },
  {
    id: 8,
    title: "Naturalized daffodils in a meadow.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/CGC_DSC_4337.jpg",
  },
  {
    id: 9,
    title: "Camassia with golden alexanders (Zizia aurea).",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/CamassiaGoldenAlexanders_CGC6983.jpg",
  },
  {
    id: 10,
    title: "Tulip blend Big Ups with pansies.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/BigUpsPansies_L1283492-4.jpg",
  },
  {
    id: 11,
    title: "Spanish bluebells planted in turf.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/6028_SpanishBluebells_CWH_L1313111.jpg",
  },
  {
    id: 12,
    title: "Daffodil Golden Echo with rhododendron P.J.M.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/3555_GoldenEcho_CGC3166.jpg",
  },
  {
    id: 13,
    title: "Virichic tulips in a mixed border.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/1658_Virichic_CGC6236.jpg",
  },
  {
    id: 14,
    title: "Clusters of French Blend Rose squeezed into nooks around boxwoods.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/1411_FrenchBlend_CGC9549-3.jpg",
  },
  {
    id: 15,
    title: "Binary Fusion tulip blend along a driveway.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/1294_BinaryFusion_AS_P1010599P-1.jpg",
  },
  {
    id: 16,
    title: "White Hot tulip blend in a parterre.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/1225_WhiteHot_CGC4731.jpg",
  },
  {
    id: 17,
    title: "Much Niceness tulip blend in a park.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/1219_MuchNiceness_CGC0020.jpg",
  },
  {
    id: 18,
    title: "Ice Follies daffodils planted on a hillside.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/CWH7039.jpg",
  },
  {
    id: 19,
    title: "Crocreation crocus blend planted in turf.",
    imgUrl:
      "https://www.colorblends.com/wp-content/uploads/2023/08/CWH0736-3.jpg",
  },
];

const tabletSize = 768;
const desktopSize = 1036;
const widescreenSize = 1216;

const calcImageWidth = (windowWidth, windowHeight) => {
  // images are 3by2 (1.5) so this formula will prevent the image height from being larger than view port height
  // padding, controls and navbar are in consideration
  if (windowHeight * 1.4 < windowWidth) {
    return windowHeight * 1.35; // change this to 1.2 if InspirationControls are showing
  }

  return windowWidth;
};

const InspirationContainer = () => {
  const [imgCount, setImageCount] = useState(0);
  const imgContainerRef = useRef(null);
  const { width, height } = useWindowDimensions();
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);

  const handleNext = () => {
    if (imgCount === inspirations.length - 1) {
      setImageCount(0);
    } else {
      setImageCount(imgCount + 1);
    }

    imgContainerRef.current.style.transform = `translateX(${
      -calcImageWidth(width, height) * imgCount.current
    }px)`;
  };

  const handlePrevious = () => {
    if (imgCount === 0) {
      setImageCount(inspirations.length - 1);
    } else {
      setImageCount(imgCount - 1);
    }
    imgContainerRef.current.style.transform = `translateX(${
      -calcImageWidth(width, height) * imgCount
    }px)`;
  };

  const handleTouchStart = (event) => {
    setTouchStart(event.targetTouches[0].clientX);
  };

  const handleTouchMove = (event) => {
    setTouchEnd(event.targetTouches[0].clientX);
  };

  const handleTouchEnd = (event) => {
    // allow only swipes not touch presses
    if (touchEnd !== 0) {
      if (touchStart - touchEnd > 75) {
        handleNext();
      }

      if (touchStart - touchEnd < -75) {
        handlePrevious();
      }
    }

    setTouchStart(0);
    setTouchEnd(0);
  };

  // a fix if users resizes window
  useEffect(() => {
    imgContainerRef.current.style.transform = `translateX(${
      -calcImageWidth(width, height) * imgCount
    }px)`;
  }, [height, imgCount, width]);

  // prevent touch scrolling up and down
  useEffect(() => {
    document.body.style.touchAction = "none";
  }, []);

  return (
    <div
      style={{
        backgroundColor: "rgb(135, 114, 114) !important",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: `calc(100vh - ${width > desktopSize ? "4" : "3"}rem)`, //include navbar
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          overflow: "hidden",
          position: "relative",
          width: `${calcImageWidth(width, height)}px`,
          borderRadius: width > desktopSize ? "1em" : "0",
          boxShadow: "0px 2px 15px 0px rgba(0, 0, 0, 0.5)",
        }}
      >
        <div
          ref={imgContainerRef}
          style={{ display: "flex", transition: "transform 400ms ease-in-out" }}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {inspirations.map((insp) => (
            <div
              key={`inspiration-${insp.title}`}
              style={{
                width: calcImageWidth(width, height),
                margin: "-10px 0", // not sure why there is random height but this is a quick fix
              }}
            >
              <div
                style={{
                  bottom: calcImageWidth(width, height) > tabletSize ? 40 : 5,
                  width: calcImageWidth(width, height),
                }}
                className="inspirationTitle is-size-5-desktop is-size-6-tablet is-size-7-mobile py-4 px-5 is-flex has-text-weight-bold"
              >
                {insp.title}
              </div>
              <img
                alt={insp.title}
                src={insp.imgUrl}
                style={{ width: calcImageWidth(width, height) }}
              />
            </div>
          ))}
        </div>
        <div
          style={{
            color: "white",
            padding: 2,
            position: "absolute",
            zIndex: 2,
            top: 10,
            left: 10,
          }}
        >
          {`${imgCount + 1} / ${inspirations.length}`}
        </div>

        <div
          onClick={handlePrevious}
          style={{
            color: "white",
            padding: 2,
            position: "absolute",
            zIndex: 2,
            left: width > widescreenSize ? "2rem" : "1rem",
            cursor: "pointer",
          }}
        >
          <i
            className={`fa-solid fa-angles-left fa-${
              calcImageWidth(width, height) > tabletSize ? "3" : "2"
            }x previous`}
          ></i>
        </div>
        <div
          onClick={handleNext}
          style={{
            color: "white",
            padding: 2,
            position: "absolute",
            zIndex: 2,
            right: width > widescreenSize ? "2rem" : "1rem",
            cursor: "pointer",
          }}
        >
          <i
            className={`fa-solid fa-angles-right fa-${
              calcImageWidth(width, height) > tabletSize ? "3" : "2"
            }x previous`}
          ></i>
        </div>
      </div>
      {/* Controls should show only if the number of inspirations is less than 7*/}
      {inspirations.length < 7 && (
        <InspirationControls
          inspirations={inspirations}
          imgCount={imgCount}
          setImageCount={setImageCount}
        />
      )}
    </div>
  );
};

const InspirationControls = ({ inspirations, imgCount, setImageCount }) => {
  const handleInspirationChange = (number) => (event) => {
    setImageCount(number);
  };

  return (
    <nav className="level is-mobile my-5" id="inspirationControls">
      {inspirations.map((inspiration, index) => (
        <div
          key={`level-item-${index}`}
          className="level-item has-text-centered"
        >
          <span
            className={`button is-rounded is-small ${
              index === imgCount ? "is-success" : "is-dark"
            }`}
            onClick={handleInspirationChange(index)}
          ></span>
        </div>
      ))}
    </nav>
  );
};

export default InspirationContainer;
