const dictionary = {
  // tulips
  tulip: "tulips",
  "tulip-blends": "tulip blends",
  singles: "tulip singles",
  "wild-tulips": "wild tulips",
  "cubed-blends": "cube blends",
  "squared-blends": "squared blends",
  bedspreads: "bedSpreads",
  "perennial-tulips": "perennial tulips",
  "darwin-hybrids": "darwin hybrids",
  "tulip-doubles": "tulip doubles",
  // daffodils
  daffodil: "daffodils",
  "daffodils-for-naturalizing": "for naturalizing",
  "the-gold-standard": "gold standard",
  "daffodil-blends": "blends",
  "daffodil-doubles": "doubles",
  uncommon: "uncommon",
  miniature: "minature",
  "landscape-size": "landscape-Size",
  fragrant: "fragrant",
  "daffodils-for-the-south": "for the south",
  // hyacinth
  hyacinth: "hyacinths",
  // allium
  allium: "alliums",
  // specialty
  specialty: "specialty",
  crocus: "crocus",
  muscari: "muscari",
  "color-your-grass": "color your grass",
  "other-spring-flowering-bulbs": "other",
  // indoor
  indoor: "indoor",
  amaryllis: "amaryllis",
  "paperwhites-indoor-bulbs": "paperwhites",
  // bloomtime
  "Very-Early": "Very Early",
  "Early-Mid": "Early-Mid",
  Early: "Early",
  Mid: "Mid",
  "Mid-Late": "Mid-Late",
  Late: "Late",
  "Very Late": "Very-Late",
  // attributes
  "deer-resistant": "Resistant To Deer",
  "shade-tolerant": "shade-tolerant",
  "for-naturalizing": "Good For Naturalizing",
  "for-the-south": "For The South",
  "for-cutting": "Good For Cutting",
};

const cleanseName = (name) => dictionary[name] || name;

export default cleanseName;
