const CatalogLoading = () => {
  const array = Array(12).fill(0)
  return (
    <div className='columns is-multiline'>
      {array.map((value, index) => 
        <div key={`${index}`} className="column is-3-desktop is-4-tablet ">
          <div className='card loadingPlaceHolderCard'>
            <div className="card-image loadingPlaceHolderImage">
              <figure className="image is-1by1">
              </figure>
            </div>
            <div className='card-content'>
            <div className='content' style={{height: '100px'}}>
            </div>
          </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CatalogLoading