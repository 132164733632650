/* eslint-disable jsx-a11y/anchor-is-valid */
import { filterBulbCategories } from "../../reducers/filterReducer"
import { useDispatch } from "react-redux"
import topFunction from '../../utils/scrollTop'

const FilterDaffodilCategories = ({ daffodilCategories }) => {
  const dispatch = useDispatch()

  const displayStyle = (category) => {
    let classStyle = []

    // display an active style for the selected daffodil category filter
    if(daffodilCategories[category]){
      classStyle.push(['is-active'])
    }

    return classStyle.join(' ')
  }

  const filterDaffodilCategoryInput = (event) => {

    switch(event.target.name){
      case 'btnDaffodilsForNaturalizing':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'daffodils-for-naturalizing'}))
        break;
      case 'btnTheGoldStandard':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'the-gold-standard'}))
        break;
      case 'btnDaffodilBlends':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'daffodil-blends'}))
        break;
      case 'btnDaffodilDoubles':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'daffodil-doubles'}))
        break;
      case 'btnUncommon':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'uncommon'}))
        break;
      case 'btnMiniature':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'miniature'}))
        break;
      case 'btnLandscapeSize':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'landscape-size'}))
        break;
      case 'btnFragrant':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'fragrant'}))
        break;
      case 'btnDaffodilsForTheSouth':
        dispatch(filterBulbCategories({genus: 'daffodil',category: 'daffodils-for-the-south'}))
        break;
      default:
        break;
    }

    topFunction()
  }

  return(
    <ul>
      <li><a href='#' name='btnDaffodilsForNaturalizing' className={displayStyle('daffodils-for-naturalizing')} onClick={filterDaffodilCategoryInput}>For Naturalizing</a></li>
      <li><a href='#' name='btnTheGoldStandard' className={displayStyle('the-gold-standard')} onClick={filterDaffodilCategoryInput}>Gold Standard</a></li>
      <li><a href='#' name='btnDaffodilBlends' className={displayStyle('daffodil-blends')} onClick={filterDaffodilCategoryInput}>Blends</a></li>
      <li><a href='#' name='btnDaffodilDoubles' className={displayStyle('daffodil-doubles')} onClick={filterDaffodilCategoryInput}>Doubles</a></li>
      <li><a href='#' name='btnUncommon' className={displayStyle('uncommon')} onClick={filterDaffodilCategoryInput}>Uncommon</a></li>
      <li><a href='#' name='btnMiniature' className={displayStyle('miniature')} onClick={filterDaffodilCategoryInput}>Minature</a></li>
      <li><a href='#' name='btnLandscapeSize' className={displayStyle('landscape-size')} onClick={filterDaffodilCategoryInput}>Landscape-Size</a></li>
      <li><a href='#' name='btnFragrant' className={displayStyle('fragrant')} onClick={filterDaffodilCategoryInput}>Fragrant</a></li>
      <li><a href='#' name='btnDaffodilsForTheSouth' className={displayStyle('daffodils-for-the-south')} onClick={filterDaffodilCategoryInput}>For The South</a></li>
    </ul>
  )
}
export default FilterDaffodilCategories