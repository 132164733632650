const CatalogNoResults = () => {
  return (
    <section className="hero is-halfheight">
      <div className="hero-body">
        <div className="container has-text-centered">
          <p className="title is-size-1">
            No Bulbs Found.
          </p>
          <p className="subtitle is-size-3">
            Try changing the filter
          </p>
        </div>
      </div>
    </section>
  )
}

export default CatalogNoResults