import { useSelector } from "react-redux";
import CatalogDisplay from "./Catalog/CatalogDisplay";
import FilterContainer from "./Filters/FilterContainer";
import sortBulbs from "../utils/sortBulbs";
import CollapseBtn from "./Elements/CollapseBtn";
import CatalogCardModal from "./Catalog/CatalogCardModal";
import FilterTouchModal from "./Filters/FilterTouchModal";

const CatalogContainer = () => {
  // returns sortedBulbs and display objects
  const [bulbs, filterDisplay, touchMode] = useSelector(({ bulbs, filter }) => [
    sortBulbs(bulbs, filter),
    filter.display,
    filter.touchMode,
  ]);

  const displayStyle = () => {
    let classStyle = ["column"];

    filterDisplay ? classStyle.push(["is-2"]) : classStyle.push(["is-narrow"]);

    return classStyle.join(" ");
  };

  return (
    <div className="container is-fluid" id="catalog">
      <div className="columns is-desktop pt-3">
        <div className={displayStyle()} id="mainContainerFilter">
          {!touchMode && (
            <FilterContainer
              key="filterContainerExample"
              displayBloomTime={bulbs.displayBloomTime}
              displayColor={bulbs.displayColor}
              displayBulbAttribute={bulbs.displayBulbAttribute}
              displayFilter={filterDisplay}
            />
          )}
        </div>
        <div className="is-hidden-touch">
          <CollapseBtn display={filterDisplay} />
        </div>
        <div className="column mx-2" id="mainContainerDisplay">
          <CatalogDisplay
            bulbs={bulbs.sortedBulbs}
            displayFilter={filterDisplay}
          />
        </div>
      </div>
      {!touchMode && <CatalogCardModal />}
      {touchMode && filterDisplay && (
        <FilterTouchModal
          displayColor={bulbs.displayColor}
          displayBloomTime={bulbs.displayBloomTime}
          displayBulbAttribute={bulbs.displayBulbAttribute}
        />
      )}
    </div>
  );
};

export default CatalogContainer;
