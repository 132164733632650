/* eslint-disable jsx-a11y/anchor-is-valid */
import { filterBulbCategories } from "../../reducers/filterReducer";
import { useDispatch } from "react-redux";
import topFunction from "../../utils/scrollTop";

const FilterSpecialtyCategories = ({ specialtyCategories }) => {
  const dispatch = useDispatch();

  const displayStyle = (category) => {
    let classStyle = [];

    // display an active style for the selected daffodil category filter
    if (specialtyCategories[category]) {
      classStyle.push(["is-active"]);
    }

    return classStyle.join(" ");
  };

  const filterSpecialtyCategoryInput = (event) => {
    switch (event.target.name) {
      case "btnCrocus":
        dispatch(
          filterBulbCategories({
            genus: "specialty",
            category: "crocus",
          })
        );
        break;
      case "btnMuscari":
        dispatch(
          filterBulbCategories({
            genus: "specialty",
            category: "muscari",
          })
        );
        break;
      case "btnColorYourGrass":
        dispatch(
          filterBulbCategories({
            genus: "specialty",
            category: "color-your-grass",
          })
        );
        break;
      case "btnOther":
        dispatch(
          filterBulbCategories({
            genus: "specialty",
            category: "other-spring-flowering-bulbs",
          })
        );
        break;
      default:
        break;
    }

    topFunction();
  };

  return (
    <ul>
      <li>
        <a
          href="#"
          name="btnCrocus"
          className={displayStyle("crocus")}
          onClick={filterSpecialtyCategoryInput}
        >
          Crocus
        </a>
      </li>
      <li>
        <a
          href="#"
          name="btnMuscari"
          className={displayStyle("muscari")}
          onClick={filterSpecialtyCategoryInput}
        >
          Muscari
        </a>
      </li>
      <li>
        <a
          href="#"
          name="btnColorYourGrass"
          className={displayStyle("color-your-grass")}
          onClick={filterSpecialtyCategoryInput}
        >
          Color Your Grass
        </a>
      </li>
      <li>
        <a
          href="#"
          name="btnOther"
          className={displayStyle("other-spring-flowering-bulbs")}
          onClick={filterSpecialtyCategoryInput}
        >
          Other
        </a>
      </li>
    </ul>
  );
};
export default FilterSpecialtyCategories;
