/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { useDispatch } from "react-redux";
import {
  filterBloomTime,
  filterColor,
  filterGenus,
  filterBulbAttributes,
  filterBulbCategories,
  setSearchString,
  toggleAvailability,
  setHeight,
} from "../../reducers/filterReducer";

const CatalogHeaderTags = ({
  availableOnly,
  genus,
  bloomTime,
  color,
  bulbAttribute,
  bulbCategory,
  searchString,
  minHeight,
  maxHeight,
}) => {
  const dispatch = useDispatch();

  const removeTag = (event) => {
    const filterType = event.target.name;

    switch (filterType) {
      case "available":
        dispatch(toggleAvailability());
        break;
      case "bloomTime":
        dispatch(filterBloomTime(bloomTime));
        break;
      case "bulbAttribute":
        dispatch(filterBulbAttributes(bulbAttribute));
        break;
      case "bulbCategory":
        dispatch(
          filterBulbCategories({ genus: genus, category: bulbCategory })
        );
        break;
      case "genus":
        dispatch(
          filterBulbCategories({ genus: genus, category: bulbCategory })
        );
        dispatch(filterGenus(genus));
        break;
      case "height":
        dispatch(setHeight({ minHeight: 0, maxHeight: 50 }));
        break;
      case "searchString":
        dispatch(dispatch(setSearchString("")));
        break;
      default:
        dispatch(filterColor(filterType));
        break;
    }
  };

  return (
    <div className="tags">
      {availableOnly && (
        <span className="tag is-danger is-large" id="headerTag">
          AVAILABLE ONLY
          <button
            name={"available"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {genus && (
        <span className="tag is-danger is-large" id="headerTag">
          {genus.toUpperCase()}
          <button
            name={"genus"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {/* if height is not the default values */}
      {!(minHeight === 0 && maxHeight === 50) && (
        <span className="tag is-danger is-large" id="headerTag">
          {minHeight}" - {maxHeight}"
          <button
            name={"height"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {bulbCategory && (
        <span className="tag is-danger is-large" id="headerTag">
          {bulbCategory.toUpperCase()}
          <button
            name={"bulbCategory"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {color.map((color) => (
        <span
          className="tag is-danger is-large"
          id="headerTag"
          key={`tag-color-${color}`}
        >
          {color.toUpperCase()}
          <button name={color} className="delete" onClick={removeTag}></button>
        </span>
      ))}

      {bloomTime && (
        <span className="tag is-danger is-large" id="headerTag">
          {bloomTime.toUpperCase()}
          <button
            name={"bloomTime"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {bulbAttribute && (
        <span className="tag is-danger is-large" id="headerTag">
          {bulbAttribute.toUpperCase()}
          <button
            name={"bulbAttribute"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}

      {searchString && (
        <span className="tag is-danger is-large" id="headerTag">
          "{searchString.toUpperCase()}"
          <button
            name={"searchString"}
            className="delete"
            onClick={removeTag}
          ></button>
        </span>
      )}
    </div>
  );
};

export default CatalogHeaderTags;
