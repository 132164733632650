/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import { filterBloomTime } from "../../reducers/filterReducer";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import topFunction from "../../utils/scrollTop";

const FilterBloomTime = ({ bloomTime, displayBloomTime, isMobile }) => {
  const [display, setDisplay] = useState(true);
  const dispatch = useDispatch();

  // toggle display menu items
  const toggleDisplay = () => {
    setDisplay(!display);
  };

  const displayStyle = (time) => {
    let classStyle = ["btnBloomTime"];

    if (isMobile) {
      classStyle.push("button", "is-medium", "is-rounded", "m-2");
    }

    // display an active style for the selected bloom time filter
    if (bloomTime[time]) {
      classStyle.push("is-active", "is-success");
    }

    // hides bloom time filters if not applicable aka zero bulbs
    if (displayBloomTime && displayBloomTime[time] === undefined) {
      classStyle.push(["is-hidden"]);
    }

    return classStyle.join(" ");
  };

  const filterBloomTimeInput = (event) => {
    //cleans btnName string to best format the propery values of the filter state
    let btnName = event.target.id;
    btnName = btnName.slice(3, btnName.length);

    switch (btnName) {
      case "VeryEarly":
        dispatch(filterBloomTime("Very-Early"));
        break;
      case "VeryLate":
        dispatch(filterBloomTime("Very-Late"));
        break;
      default:
        dispatch(filterBloomTime(btnName));
    }

    topFunction();
  };

  if (isMobile) {
    return (
      <div className="field is-grouped is-grouped-multiline">
        <div
          id="btnVeryEarly"
          className={displayStyle("Very-Early")}
          onClick={filterBloomTimeInput}
        >
          Very Early
        </div>
        <div
          id="btnEarly"
          className={displayStyle("Early")}
          onClick={filterBloomTimeInput}
        >
          Early
        </div>
        <div
          id="btnEarly-Mid"
          className={displayStyle("Early-Mid")}
          onClick={filterBloomTimeInput}
        >
          Early-Mid
        </div>
        <div
          id="btnMid"
          className={displayStyle("Mid")}
          onClick={filterBloomTimeInput}
        >
          Mid
        </div>
        <div
          id="btnMid-Late"
          className={displayStyle("Mid-Late")}
          onClick={filterBloomTimeInput}
        >
          Mid-Late
        </div>
        <div
          id="btnLate"
          className={displayStyle("Late")}
          onClick={filterBloomTimeInput}
        >
          Late
        </div>
        <div
          id="btnVeryLate"
          className={displayStyle("Very-Late")}
          onClick={filterBloomTimeInput}
        >
          Very Late
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p
          className="menu-label my-4 has-text-weight-bold is-clickable"
          onClick={toggleDisplay}
        >
          Bloom Time
          <span className="icon is-small filterCategoryCollapse">
            {display ? (
              <FontAwesomeIcon icon={faAngleUp} />
            ) : (
              <FontAwesomeIcon icon={faAngleDown} />
            )}
          </span>
        </p>
        {display && (
          <ul className="menu-list">
            <li>
              <a
                href="#"
                id="btnVeryEarly"
                className={displayStyle("Very-Early")}
                onClick={filterBloomTimeInput}
              >
                Very Early
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnEarly"
                className={displayStyle("Early")}
                onClick={filterBloomTimeInput}
              >
                Early
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnEarly-Mid"
                className={displayStyle("Early-Mid")}
                onClick={filterBloomTimeInput}
              >
                Early-Mid
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnMid"
                className={displayStyle("Mid")}
                onClick={filterBloomTimeInput}
              >
                Mid
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnMid-Late"
                className={displayStyle("Mid-Late")}
                onClick={filterBloomTimeInput}
              >
                Mid-Late
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnLate"
                className={displayStyle("Late")}
                onClick={filterBloomTimeInput}
              >
                Late
              </a>
            </li>
            <li>
              <a
                href="#"
                id="btnVeryLate"
                className={displayStyle("Very-Late")}
                onClick={filterBloomTimeInput}
              >
                Very Late
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
};

export default FilterBloomTime;
