import axios from "axios"

const { REACT_APP_API } = process.env

const baseUrl = REACT_APP_API

const getAll = async () => {
  const response = await axios.get(baseUrl)
  
  return response.data
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getAll,
}