/* eslint-disable jsx-a11y/anchor-is-valid */
import { useDispatch } from "react-redux";
import { filterGenus } from "../../reducers/filterReducer";
import FilterTulipCategories from "./FilterTulipCategories";
import FilterDaffodilCategories from "./FilterDaffodilCategories";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import topFunction from "../../utils/scrollTop";
import FilterSpecialtyCategories from "./FilterSpecialtyCategories";

const FilterGenus = ({ genus, bulbCategories, isMobile }) => {
  const dispatch = useDispatch();

  const filterGenusInput = (event) => {
    let btnName = null;

    // determines what type of input (button or select)
    if (event.currentTarget.name !== "") {
      //cleans btnName string to best format the propery values of the fiter state
      btnName = event.currentTarget.name;
      btnName = btnName?.slice(3, btnName.length).toLowerCase();
    } else {
      btnName = event.currentTarget.value;
    }

    switch (btnName) {
      case "all":
        dispatch(filterGenus(genus));
        break;
      default:
        dispatch(filterGenus(btnName));
    }

    topFunction();
  };
  if (isMobile) {
    return (
      <div className="field">
        <div className="control">
          <div className="select is-large-tablet is-rounded is-success">
            <select onChange={filterGenusInput}>
              <option value={"all"}>All</option>
              <option value="tulip">Tulip</option>
              <option value="daffodil">Daffodil</option>
              <option value="hyacinth">Hyacinth</option>
              <option value="allium">Allium</option>
              <option value="specialty">Specialty</option>
            </select>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <p className="menu-label my-4 has-text-weight-bold">Bulb Type</p>
        <ul className="menu-list">
          <li>
            <a
              href="#"
              name="btnTulip"
              className={genus.tulip ? "is-active" : ""}
              onClick={filterGenusInput}
            >
              Tulips
              <span className="icon is-small filterCategoryCollapse">
                {genus.tulip ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </span>
            </a>
            {genus.tulip && (
              <FilterTulipCategories tulipCategories={bulbCategories.tulip} />
            )}
          </li>
          <li>
            <a
              href="#"
              name="btnDaffodil"
              className={genus.daffodil ? "is-active" : ""}
              onClick={filterGenusInput}
            >
              Daffodils
              <span className="icon is-small filterCategoryCollapse">
                {genus.daffodil ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </span>
            </a>
            {genus.daffodil && (
              <FilterDaffodilCategories
                daffodilCategories={bulbCategories.daffodil}
              />
            )}
          </li>
          <li>
            <a
              href="#"
              name="btnHyacinth"
              className={genus.hyacinth ? "is-active" : ""}
              onClick={filterGenusInput}
            >
              Hyacinths
            </a>
          </li>
          <li>
            <a
              href="#"
              name="btnAllium"
              className={genus.allium ? "is-active" : ""}
              onClick={filterGenusInput}
            >
              Alliums
            </a>
          </li>
          <li>
            <a
              href="#"
              name="btnSpecialty"
              className={genus.specialty ? "is-active" : ""}
              onClick={filterGenusInput}
            >
              Specialty
              <span className="icon is-small filterCategoryCollapse">
                {genus.specialty ? (
                  <FontAwesomeIcon icon={faAngleUp} />
                ) : (
                  <FontAwesomeIcon icon={faAngleDown} />
                )}
              </span>
            </a>
            {genus.specialty && (
              <FilterSpecialtyCategories
                specialtyCategories={bulbCategories.specialty}
              />
            )}
          </li>
        </ul>
      </div>
    );
  }
};

export default FilterGenus;
