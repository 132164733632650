import { useSelector } from "react-redux";
import { useState } from "react";
import CatalogCardGrid from "./CatalogCardGrid";
import CatalogHeader from "./CatalogHeader";
import CatalogHeaderTags from "./CatalogHeaderTags";
import ScrollTopBtn from "../Elements/ScrollTopBtn";
import useResizeObserver from "@react-hook/resize-observer";

const CatalogDisplay = ({ bulbs, displayFilter }) => {
  const [maxDisplayHeight, setMaxDisplayHeight] = useState("100vh");
  const filterContainer = document.getElementById("filterContainer");

  // Resizes the catalog display to be the same height as the filter
  // Unless the filter is smaller than the height view port
  useResizeObserver(filterContainer, (entry) => {
    const windowHeight = window.innerHeight;
    entry.contentRect.height > windowHeight
      ? setMaxDisplayHeight(entry.contentRect.height)
      : setMaxDisplayHeight("100vh");
  });

  // find active filters
  const [
    availableOnly,
    bloomTime,
    bulbAttribute,
    color,
    genus,
    minHeight,
    maxHeight,
    searchString,
  ] = useSelector(({ filter }) => [
    filter.availableOnly,
    Object.keys(filter.bloomTime).find((key) => filter.bloomTime[key]),
    Object.keys(filter.bulbAttributes).find(
      (key) => filter.bulbAttributes[key]
    ),
    // Object.keys(filter.color).find(key => filter.color[key]), // retrieve one color
    Object.keys(filter.color).filter((type) => filter.color[type]), // retrieve an array of colors
    Object.keys(filter.genus).find((key) => filter.genus[key] === true),
    filter.minHeight,
    filter.maxHeight,
    filter.searchString,
  ]);

  // find current selected bulb category filter
  const bulbCategory = useSelector(({ filter }) => {
    // incase the user tries to delete tne genus filter before the genus category filter
    try {
      return Object.keys(filter.bulbCategories?.[genus]).find(
        (key) => filter.bulbCategories[genus][key]
      );
    } catch (error) {
      return undefined;
    }
  });

  return (
    <div
      id="catalogDisplay"
      className="px-4"
      style={{ maxHeight: maxDisplayHeight }}
    >
      <CatalogHeader genus={genus} />
      <CatalogHeaderTags
        availableOnly={availableOnly}
        bloomTime={bloomTime}
        color={color}
        genus={genus}
        bulbAttribute={bulbAttribute}
        bulbCategory={bulbCategory}
        minHeight={minHeight}
        maxHeight={maxHeight}
        searchString={searchString}
      />
      <CatalogCardGrid
        bulbs={bulbs}
        selectedGenus={genus}
        color={color}
        bloomTime={bloomTime}
        displayFilter={displayFilter}
      />
      <ScrollTopBtn />
    </div>
  );
};

export default CatalogDisplay;
