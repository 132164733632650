/* eslint-disable jsx-a11y/anchor-is-valid */
import { toggleAvailability } from "../../reducers/filterReducer"
import { useDispatch } from "react-redux"

const FilterAvailability = ({ availableOnly, isMobile }) => {
  const dispatch = useDispatch()
  
  const filterAvailabilityInput = () => {
    dispatch(toggleAvailability())
  }

  if(isMobile) {
    return(
      <div className="field">
        <div className="control">
          <label className="checkbox">
            <input className='is-active is-large' type="checkbox" checked={availableOnly} onChange={filterAvailabilityInput} />
              {' Available Only'}
          </label>
        </div>
      </div>
    )
  }
  else {
    return (
      <div>
        <ul className='menu-list'>
          <li>
            <a href='#' name='available' className={availableOnly ? 'is-active' : ''} onClick={filterAvailabilityInput}>
              Available Only
            </a>
          </li>
        </ul>
      </div>
    )
  }
}

export default FilterAvailability