/* eslint-disable jsx-a11y/anchor-is-valid */
import { filterBulbCategories } from "../../reducers/filterReducer"
import { useDispatch } from "react-redux"
import topFunction from '../../utils/scrollTop'

const FilterTulipCategories= ({ tulipCategories }) => {
  const dispatch = useDispatch()

  const displayStyle = (category) => {
    let classStyle = []

    // display an active style for the selected tulip category filter
    if(tulipCategories[category]){
      classStyle.push(['is-active'])
    }

    return classStyle.join(' ')
  }

  const filterTulipCategoryInput = (event) => {

    switch(event.target.name){
      case 'btnTulipBlends':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'tulip-blends'}))
        break;
      case 'btnSingles':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'singles'}))
        break;
      case 'btnWildTulips':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'wild-tulips'}))
        break;
      case 'btnCubedBlends':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'cubed-blends'}))
        break;
      case 'btnSquaredBlends':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'squared-blends'}))
        break;
      case 'btnBedSpreads':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'bedspreads'}))
        break;
      case 'btnPerennialTulips':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'perennial-tulips'}))
        break;
      case 'btnDarwinHybrids':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'darwin-hybrids'}))
        break;
      case 'btnTulipDoubles':
        dispatch(filterBulbCategories({genus: 'tulip',category: 'tulip-doubles'}))
        break;
      default:
        break;
    }

    topFunction()
  }

  return(
    <ul>
      <li><a href='#' name='btnTulipBlends' className={displayStyle('tulip-blends')} onClick={filterTulipCategoryInput}>Tulip Blends</a></li>
      <li><a href='#' name='btnSingles' className={displayStyle('singles')} onClick={filterTulipCategoryInput}>Tulip Singles</a></li>
      <li><a href='#' name='btnWildTulips' className={displayStyle('wild-tulips')} onClick={filterTulipCategoryInput}>Wild Tulips</a></li>
      <li><a href='#' name='btnCubedBlends' className={displayStyle('cubed-blends')} onClick={filterTulipCategoryInput}>Cube Blends</a></li>
      <li><a href='#' name='btnSquaredBlends' className={displayStyle('squared-blends')} onClick={filterTulipCategoryInput}>Squared Blends</a></li>
      <li><a href='#' name='btnBedSpreads' className={displayStyle('bedspreads')} onClick={filterTulipCategoryInput}>BedSpreads</a></li>
      <li><a href='#' name='btnPerennialTulips' className={displayStyle('perennial-tulips')} onClick={filterTulipCategoryInput}>Perennial Tulips</a></li>
      <li><a href='#' name='btnDarwinHybrids' className={displayStyle('darwin-hybrids')} onClick={filterTulipCategoryInput}>Darwin Hybrids</a></li>
      <li><a href='#' name='btnTulipDoubles' className={displayStyle('tulip-doubles')} onClick={filterTulipCategoryInput}>Tulip Doubles</a></li>
    </ul>
  )
}
export default FilterTulipCategories