import { useDispatch, useSelector } from "react-redux";
import { toggleDisplay } from "../../reducers/filterReducer";
import FilterAvailability from "./FilterAvailability";
import FilterBloomTime from "./FilterBloomTime";
import FilterBulbAttributes from "./FilterBulbAttributes";
import FilterColor from "./FilterColor";
import FilterGenus from "./FilterGenus";
import FilterHeight from "./FilterHeight";
import FilterMobileCategories from "./FilterMobileCategories";

const FilterTouchModal = ({
  displayColor,
  displayBloomTime,
  displayBulbAttribute,
}) => {
  const filter = useSelector(({ filter }) => filter);
  const { REACT_APP_ENABLE_AVAILABILITY } = process.env;
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(toggleDisplay());
  };

  return (
    <div className="modal is-active">
      <div
        className="modal-background"
        id="mobileFilterBackGround"
        onClick={closeModal}
      ></div>
      <div className="modal-content" id="mobileFilterContent">
        <div className="box">
          <div className="is-flex is-justify-content-flex-end">
            <button
              className="delete is-large"
              aria-label="close"
              onClick={closeModal}
            ></button>
          </div>
          <section
            className="section has-text-centered"
            id="mobileFilterHeaderSection"
          >
            <h1 className="title is-size-1-tablet is-size-2-mobile">FILTER</h1>
          </section>
          <section className="section" id="mobileFilterInputSection">
            {REACT_APP_ENABLE_AVAILABILITY === "true" && (
              <FilterAvailability
                availableOnly={filter.availableOnly}
                isMobile={true}
              />
            )}

            <div className="columns is-flex">
              <div className="column is-narrow">
                <h1 className="title is-size-2-tablet is-size-4-mobile">
                  Bulb Type
                </h1>
                <FilterGenus
                  genus={filter.genus}
                  bulbCategories={filter.bulbCategories}
                  isMobile={true}
                />
              </div>
              <div className="column">
                <FilterMobileCategories filter={filter} />
              </div>
            </div>

            <h1 className="title is-size-2-tablet is-size-4-mobile">Colors</h1>
            <FilterColor
              colors={filter.color}
              displayColor={displayColor}
              isMobile={true}
            />

            <h1 className="title is-size-2-tablet is-size-4-mobile">Height</h1>
            <FilterHeight isMobile={true} />

            <h1 className="title is-size-2-tablet is-size-4-mobile">
              Bloom Time
            </h1>
            <FilterBloomTime
              bloomTime={filter.bloomTime}
              displayBloomTime={displayBloomTime}
              isMobile={true}
            />

            <h1 className="title is-size-2-tablet is-size-4-mobile">
              Attributes
            </h1>
            <FilterBulbAttributes
              bulbAttributes={filter.bulbAttributes}
              displayBulbAttributes={displayBulbAttribute}
              isMobile={true}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default FilterTouchModal;
